<template>
  <v-container>
    <v-card class="elevation-2 mx-auto" :loading="loading" max-width="600">
      <v-card-title>Edit Object</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-select
                v-model="form_data.position_id"
                :error-messages="form_error.position_id"
                :items="positions"
                item-text="title"
                item-value="vo_position_id"
                label="Object Position"
                outlined
                dense
                readonly
                @change="getType(form_data.position_id)"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0" v-if="form_data.upload_url">
              <v-img :src="form_data.upload_url" width="30%" v-if="form_data.type=='IMAGE'"></v-img>
              <video :src="form_data.upload_url" v-if="form_data.type=='VIDEO'" controls style="max-width:100%"></video>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="py-2">
              <v-file-input
                v-model="form_data.object_file"
                :error-messages="form_error.object_file"
                accept="image/*, video/*"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
                v-show="show_file"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-file-input
                v-model="form_data.object_file_thumbnail"
                :error-messages="form_error.object_file_thumbnail"
                accept="image/*"
                placeholder="Select video thumbnail"
                prepend-icon="mdi-file-image"
                outlined
                dense
                :hint="'Max file size allowed is 10 MB (Only image file types are allowed)'"
                persistent-hint
                v-if="show_thumbnail"
              ></v-file-input>
              <v-text-field
                v-model="form_data.link"
                :error-messages="form_error.link"
                dense
                label="Link"
                outlined
                class="mb-5"
                v-if="!show_contact_details"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pa-2  pb-8">
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-btn x-small :href="form_data.video_thumbnail" target="_blank">View Thumbnail</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12" class="py-0">
              <v-textarea
                v-model="form_data.contact_details"
                :error-messages="form_error.contact_details"
                dense
                rows="3"
                label="Contact Details"
                outlined
                v-if="show_contact_details"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-btn
            class="mr-4"
            color="secondary"
            @click="upadteObject"
            :loading="loading"
            :disabled="loading"
          >Update</v-btn>
        </form>
      </v-card-text>
    </v-card>
    <v-btn small color="secondary" fixed fab bottom left :to="'/vendor/details/'+form_data.vendor_id">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "EditObject",
  data() {
    return {
      positions: [],
      form_data: {},
      form_error: {},
      loading: false,
      data: {},
      show_file : false,
      show_contact_details : false,
      show_thumbnail:false,
    };
  },
  watch : {
    "form_data.position_id" : function(value){
      console.log(value);
    }
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getObjectDetails() {
      this.loading = true;
      let _self = this;
      this.$axios.get(`admin/vendor/object/details?object_id=` + _self.$route.params.id)
        .then((res) => {
          _self.form_error = {};
          if (res.status) {
            let data = res.data.data;
            for (let key in data) {
              _self.form_data[key] = data[key];
            }
          } else {
            let errors = res.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.getPositionList();
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getPositionList() {
      let _self = this;
      this.$axios.get(`admin/vendor/position_list?vendor_id=`+_self.form_data.vendor_id)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.positions = response_data.data;
          }
          _self.getType(_self.form_data.position_id)
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    upadteObject() {
      let _self = this;
      this.loading = "secondary";
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.object_file) {
        formData.append("object_upload_file", this.form_data.object_file);
      }
      if (this.form_data.object_file_thumbnail) {
        formData.append("object_file_thumbnail", this.form_data.object_file_thumbnail);
      }
      formData.append("is_social", !this.show_file);
      formData.append("is_contact_details", this.show_contact_details);
      this.$axios.post(`admin/vendor/object/edit`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            this.user_data = response_data.user_data;
            _self.$router.push("/vendor/details/"+_self.form_data.vendor_id);
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              console.log(errors[key]);
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getType(position_id){
      var result = this.positions.filter(obj => {
        return obj.vo_position_id === position_id
      })
      console.clear()
      console.log(this.positions);
      this.show_thumbnail = result[0].type == 'VIDEO' ? true:false
      this.show_file = (result[0].type=='CONTACT')?false:true;
      this.show_contact_details = (result[0].type=='CONTACT')?true:false;
    }
  },
  mounted: function () {
    this.getObjectDetails();
  },
};
</script>